import React from "react"
import GenericContent from "../components/generic-content.js"
import styles from "./grid-item-quote.module.scss"

export default ({ quote }) => (
  <GenericContent
    flex="true"
    padding="padding-s"
    content={
      <div className={styles.quote}>
        <p>{quote}</p>
      </div>
    }
  ></GenericContent>
)
