import { graphql } from "gatsby"
import MarkdownIt from "markdown-it"
import React from "react"
import Footer from "../components/footer"
import GenericContent from "../components/generic-content.js"
import genericContentStyles from "../components/generic-content.module.scss"
import GridItemGallery from "../components/grid-item-gallery"
import GridItemQuote from "../components/grid-item-quote"
import Header from "../components/header"
import paginationStyles from "../components/pagination.module.scss"
import SEO from "../components/seo"
// import "../styles/index.scss"
import { getRandomDelay, getRandomDuration } from "../utils/utils"
import md1 from "markdown-it-container"

const md = new MarkdownIt()
md.use(md1, "pageContent", {})

export default class ProductTemplate extends React.Component {
  constructor(props) {
    super(props)
    const {
      imagesLeft,
      imagesRightTop,
      imagesRightBottom,
      title,
      contentPagination,
      quote,
      description,
      metaImage,
    } = {
      ...this.props.data.allMarkdownRemark.nodes[0].frontmatter,
    }
    this.state = {
      imagesLeft,
      imagesRightTop,
      imagesRightBottom,
      title,
      contentPagination,
      quote,
      description,
      pageIndex: 0,
      metaImage,
      hasPagination: contentPagination.length > 1,
    }
  }
  handlePageClick(index) {
    this.setState({
      pageIndex: index,
    })
  }
  buildPagination() {
    const pagination = []
    this.state.contentPagination.forEach((item, index) => {
      const pageIndexDisplay = index + 1
      pagination.push(
        <li
          key={index}
          className={
            this.state.pageIndex === index ? paginationStyles.current : ""
          }
        >
          <a
            href="#"
            onClick={() => {
              this.handlePageClick(index)
            }}
          >
            {pageIndexDisplay}
          </a>
        </li>
      )
    })
    if (pagination.length > 1) {
      return (
        <ul
          className={`${paginationStyles.pagination} ${paginationStyles.productPagination}`}
        >
          {pagination}
        </ul>
      )
    } else {
      return ""
    }
  }
  buildContentDivs() {
    let content = ""
    this.state.contentPagination.forEach((item, index) => {
      content += md.render(`::: pageContent click me\n${item}\n:::\n`)
    })
    return (
      <div
        className={`paginatedContent paginatedContent-${this.state.pageIndex}`}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        ></div>
      </div>
    )
  }

  render() {
    return (
      <div>
        <SEO
          title={this.state.title}
          description={this.state.description}
          metaImage={this.state.metaImage}
          pathname={this.props.location.pathname}
        />
        <Header></Header>
        <main className="grid grid-col-1-2-3">
          <div className="grid-item-1x2">
            <GridItemGallery
              images={this.state.imagesLeft}
              delay={getRandomDelay()}
              duration={getRandomDuration()}
            ></GridItemGallery>
          </div>
          <div className="grid-item-1x1">
            <GenericContent
              padding={`padding-s ${
                this.state.hasPagination === true
                  ? genericContentStyles.hasPagination
                  : ""
              }`}
              content={
                <div>
                  <h1>{this.state.title}</h1>
                  {this.buildContentDivs()}
                  {this.buildPagination()}
                </div>
              }
            ></GenericContent>
          </div>
          <div className="grid-item-1x1 grid-item-inner">
            <div>
              <GridItemQuote quote={this.state.quote}></GridItemQuote>
            </div>
            <div>
              <GridItemGallery
                images={this.state.imagesRightTop}
                delay={getRandomDelay()}
                duration={getRandomDuration()}
              ></GridItemGallery>
            </div>
            <div className="grid-item-2x1">
              <GridItemGallery
                images={this.state.imagesRightBottom}
                delay={getRandomDelay()}
                duration={getRandomDuration()}
              ></GridItemGallery>
            </div>
          </div>
        </main>
        <Footer></Footer>
      </div>
    )
  }
}

// TODO Seperate graphql file? Sharing images on other templates?
export const query = graphql`
  query($slug: String!) {
    allMarkdownRemark(filter: { fields: { slug: { eq: $slug } } }) {
      nodes {
        frontmatter {
          imagesLeft {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          imagesRightTop {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          imagesRightBottom {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          title
          contentPagination
          quote
          description
          metaImage {
            childImageSharp {
              fixed {
                ...GatsbyImageSharpFixed_withWebp
              }
            }
          }
        }
      }
    }
  }
`
