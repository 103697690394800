import MarkdownIt from "markdown-it"
import React from "react"
import styles from "./generic-content.module.scss"

export default class GenericContent extends React.Component {
  handleFlex(flex) {
    if (flex === "true") {
      flex = "flex"
    } else {
      flex = ""
    }
    return flex
  }
  handleFlexAlign(flexAlign) {
    return flexAlign ? flexAlign : "flex-align-center"
  }
  handlePadding(padding) {
    if (!padding) {
      padding = ""
    }
    return padding
  }
  handleContent(content) {
    const type = typeof content
    const flexAlign = this.handleFlexAlign(this.props.flexAlign)
    const md = new MarkdownIt()
    switch (type) {
      case "object":
        return <div className={flexAlign}>{content}</div>
      case "string":
        return (
          <div
            className={flexAlign}
            dangerouslySetInnerHTML={{ __html: md.render(content) }}
          ></div>
        )
      default:
        break
    }
  }
  render() {
    const flex = this.handleFlex(this.props.flex)
    const padding = this.handlePadding(this.props.padding)
    const classNames = [padding, flex, "generic-content"]
      .join(" ")
      .trim() /* TODO find better way of concatting classnames*/
    const content = this.props.content
    return (
      <div className={styles.genericContent}>
        <div className={classNames}>{this.handleContent(content)}</div>
      </div>
    )
  }
}
